<template>
	<div class="row">
		<div class="col-md-12">

			<div class="mb-3 titulosg h4">Mis Clases</div>

			<div class="row">
					
				<div class="col-md-8">
					<div class="mb-2 bg-white p-4 rounded" :style="'border-left: 3px solid '+ item.asignaturas.color"  v-for="item in clases" :key="item.idacaclase">

						<img :src="archivospublicos+'/asignaturas/'+item.asignaturas.icono" class="float-start me-3">

						<span class="p-2 pe-3 ps-3 bg-light rounded-pill float-end">
							<i class="fas fa-paperclip"></i> <span class="titulosg h-5">{{item.archivos.length}}</span>	 					
						</span>
						<span class="p-2 pe-3 ps-3 float-end">
							Periodo:  {{item.periodos.numero}}
						</span>

						<router-link  :to="{ path: '/misclases/'+ item.slug }"><span class=" titulosg">{{item.titulos}}</span></router-link> <br>
						<span class="text-capitalize"> <i class="far fa-calendar-alt"></i>  {{ formatoFecha(item.fechainicio) }} </span> <br>

					{{item.asignaturas.descripcion}}<br> 
						
					</div>
				</div>
				<div class="col-md-4">

				</div>
			</div>
			
		</div>
		<div class="col-md-4">

		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import moment from 'moment'

export default {
	setup(props) {
		
		const clases = ref([])

		const consultaclases = () => {
			axios.get('/api/clasesxestudiante').then(response => {
				clases.value = response.data 
			})
		}

		const formatoFecha = (date) => {
            return moment(date).format(' D MMMM [de] YYYY');
        }


		onMounted(() => {
			consultaclases()
		})

		return {
			clases,

			//funciones
			formatoFecha,
		}
	}
}
</script>
<style scoped>
      a {
    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: background-size .3s;
}

    a:hover, a:focus {
        background-size: 100% 2px;
        cursor: pointer;
    }

</style>